<style scoped>
</style>
<template>
  <div v-if="labelList.length > 0">
    <Tabs :value="active" @on-click="chooseItem">
      <TabPane
        v-for="(item, index) in labelList"
        :key="index"
        :label="item"
        :name="item"
      ></TabPane>
    </Tabs>
  </div>
</template>

<script>
import _ from "lodash";

import {
  getUserLabelData,
  getContentLabelData,
  getKeyWordLabelData,
} from "../api/cacheDept";

export default {
  name: "labelCom",
  data() {
    return {
      active: "",
      labelList: [],
    };
  },
  props: {
    // 标签类型 user | keyword | content
    labelType: {
      type: String,
      default: "user",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    defaultList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  watch: {
    refresh: function () {
      console.log("------", this.refresh);
      if ("keyword" == this.labelType) {
        this.initLabel();
      } else {
        this.$emit("chooseTab", [this.active]);
      }
    },
    defaultList: function (val) {
      this.labelList = this.defaultList;
      this.active = this.labelList[0];
    },
  },
  computed: {},
  methods: {
    chooseItem(name) {
      const item = _.find(this.labelList, (o) => {
        return o == name;
      });
      this.active = item || {};
      this.$emit("chooseTab", [item]);
    },
    async initLabel() {
      this.labelList = await this.getLabelByType(this.labelType);
      const _curItem = this.labelList[0] || {};
      this.active = _curItem ? _curItem : {};
      this.$emit("chooseTab", [_curItem]);
    },
    async getLabelByType(labelType) {
      if ("user" == labelType) {
        return await getUserLabelData();
      }
      if ("content" == labelType) {
        return await getContentLabelData();
      }
      if ("keyword" == labelType) {
        return await getKeyWordLabelData(this.startDate, this.endDate);
      }
      return [];
    },
  },

  async created() {
    console.log(" this.refresh", this.refresh);
    this.initLabel();
  },
};
</script>
