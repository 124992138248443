<style scoped>
.contentKeyWord {
  text-align: center;
  background: white;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* box-shadow: 0 1px 3px rgba(105, 93, 93, 0.2); */
  padding: 3px 2px;
  box-shadow: 0px 3px 3px #1e9bcc0d;
}

::-webkit-scrollbar {
  width: 0;
}

.chooseDeptFormItem {
  padding: 6px 10px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  line-height: 30px;
}
.chooseDeptFormItem .chooseDeptFormItemTitle {
  font-weight: bold;
  color: #2d4761;
  flex: 1;
}
.chooseDeptFormItem .detail {
  flex: 1;
}
</style>
<template>
  <div v-if="showModal">
    <Modal
      width="100"
      placement="left"
      :closable="false"
      v-model="showTime"
      title="选择关键字检索时间"
      :mask-closable="false"
    >
      <p style="font-size: 12px">
        不选择默认最近三个月，请选择完时间，重新筛选关键字
      </p>
      <div class="chooseDeptFormItem">
        <div class="chooseDeptFormItemTitle">开始日期</div>
        <div class="detail">
          <DatePicker
            format="yyyy-MM-dd"
            type="date"
            :clearable="false"
            :value="pickerValue"
            @on-change="setStartDateValue"
            placeholder="请选择时间"
            width="200px"
          ></DatePicker>
        </div>
      </div>

      <div class="chooseDeptFormItem">
        <div class="chooseDeptFormItemTitle">结束日期</div>
        <div class="detail">
          <DatePicker
            format="yyyy-MM-dd"
            type="date"
            :clearable="false"
            :value="pickerEndValue"
            @on-change="setEndDateValue"
            placeholder="请选择时间"
            width="200px"
          ></DatePicker>
        </div>
      </div>

      <div
        slot="footer"
        style="
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        "
      >
        <Button type="info" size="large" @click="chooseDateOk">确定</Button>
        <Button type="default" size="large" @click="showTime = false"
          >取消</Button
        >
      </div>
    </Modal>

    <Modal
      style="z-index: 99"
      v-model="sureChooseLabel"
      fullscreen
      :closable="false"
    >
      <loading-cmp v-if="loading"></loading-cmp>
      <div slot="header">
        <Input
          style="width: 90vw"
          clearable
          @on-search="searchCon"
          search
          enter-button="查询"
          placeholder="请输入筛选关键字"
          v-model="searchVal"
        />
      </div>

      <div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            position: relative;
            margin: 6px 0;
          "
          v-show="listCon.length"
        >
          <div style="color: #ccc">
            {{ `选择关键字${chooseList.length}/${listCon.length}` }}
          </div>
          <div
            :class="`deptlistItem ${allcheck ? 'active' : ''}`"
            @click="clickHandler()"
          >
            全选
            <input :checked="allcheck" type="checkbox" />
          </div>
        </div>
        <div style="overflow: scroll">
          <div
            v-for="(item, index) in listCon"
            :key="index"
            class="contentKeyWord"
          >
            <div style="flex: 1; text-align: left">{{ item.name }}</div>
            <div
              style="
                display: flex;
                flex: 1;
                padding: 8px 0;
                justify-content: flex-end;
              "
            >
              <div
                :class="`deptlistItem ${allcheck ? 'active' : ''}`"
                @click="clickHandler(item, index)"
              >
                <input
                  v-model="item.checked"
                  :id="`check${index}`"
                  :ref="`check${index}`"
                  :checked="item.checked"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showLoadData"
        style="width: 100%; padding: 10px 0; text-align: center; color: #795548"
      >
        没有更多数据了
      </div>

      <div
        slot="footer"
        style="
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        "
      >
        <Button type="info" size="large" @click="okItem">确定</Button>
        <Button type="default" size="large" @click="cancel">取消</Button>
        <div @click="showtext" style="position: absolute; right: 5px">
          <svg
            t="1672370678750"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2734"
            width="20"
            height="20"
          >
            <path
              d="M512 128C299.92 128 128 299.92 128 512s171.92 384 384 384 384-171.92 384-384S724.08 128 512 128z m0 704c-176.448 0-320-143.552-320-320s143.552-320 320-320 320 143.552 320 320-143.552 320-320 320z"
              fill="#ccc"
              p-id="2735"
            ></path>
            <path
              d="M512 256c-88.224 0-160 71.776-160 160a32 32 0 1 0 64 0c0-52.944 43.056-96 96-96s96 43.056 96 96-43.056 96-96 96a32 32 0 0 0-32 32v64a32 32 0 1 0 64 0v-35.232c72.944-14.864 128-79.52 128-156.768 0-88.224-71.776-160-160-160z"
              fill="#ccc"
              p-id="2736"
            ></path>
            <path
              d="M512 720m-48 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z"
              fill="#ccc"
              p-id="2737"
            ></path>
          </svg>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import diaLogliu from "./dialogLiu.vue";

import { statsPost } from "../api/httpApi";
import loadingCmp from "../components/loadingCmp.vue";
import searchTop from "./searchTopContent.vue";
import keywordListCom from "./keywordListCom.vue";

let curDate = new Date();
import commonTop from "./commonTop.vue";
export default {
  name: "labelCom",
  data() {
    return {
      showLoadData: false,

      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
        zIndex: 99999,
      },
      loading: false,
      showTime: false,
      // 选择时间
      pickerValue: new Date(curDate.getTime() - 7 * 24 * 60 * 60 * 1000),
      pickerEndValue: curDate,
      startDate: "",
      endDate: "",

      close: false,
      searchVal: "",

      refreshDataStatus: false,
      loadMoreShow: false,
      isLoadMore: true,

      listCon: [], // 当前页的任务数据

      // 文章列表
      allcheck: false,
      chooseList: [],

      sureChooseLabel: false,
      curLabel: {},
      curShowList: [],
      active: "",

      // 提示框
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },
  components: {
    commonTop,
    diaLogliu,
    loadingCmp,
    searchTop,
    keywordListCom,
  },
  props: {
    // 标签类型 user | keyword | content
    labelType: {
      type: String,
      default: "user",
    },
    defaultStartDate: {
      type: String,
      default: "",
    },
    defaultEndDate: {
      type: String,
      default: "",
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    showModal: function (val) {
      this.sureChooseLabel = this.showModal;
    },
  },
  computed: {},
  methods: {
    showtext() {
      console.log("------", this.showTime);
      this.showTime = !this.showTime;
    },
    // 文章列表组件
    clickHandler(item, key) {
      const curchoose = item ? item : "allcheck";
      this.chooseList = [];
      // 若点击全选
      if (curchoose == "allcheck") {
        this.allcheck = !this.allcheck;
        if (this.allcheck) {
          for (const [index, iterator] of this.listCon.entries()) {
            iterator["checked"] = true;
            this.$refs[`check${index}`][0].checked = true;
          }
          this.chooseList = _.map(this.listCon, "name"); // 全选当前可见文章的全部数据
        } else {
          for (const [index, iterator] of this.listCon.entries()) {
            iterator["checked"] = false;
            this.$refs[`check${index}`][0].checked = false;
          }
          this.chooseList = []; // 清空选项，默认全选
        }
      } else {
        let _tmpallcheckStatus = true;
        this.listCon[key].checked = this.$refs[`check${key}`][0].checked;
        for (let [index, iterator] of this.listCon.entries()) {
          // 全选/非权限
          if (!this.$refs[`check${index}`][0].checked) {
            if (_tmpallcheckStatus) {
              // 判定是否全选状态，当已经有非选中项，则不重新赋值
              _tmpallcheckStatus = false;
            }
          } else {
            this.chooseList.push(iterator.name); // 记录选中文章信息
          }
        }

        this.allcheck = _tmpallcheckStatus;
      }

      this.$emit("chooseItem", this.chooseList);
    },
    chooseItem(list) {
      // 多选文章
      this.chooseList = list;
    },

    cancel() {
      this.searchVal = "";
      this.$emit("cancel");
    },

    async searchCon() {
      try {
        this.loading = true;
        this.showLoadData = false;
        console.log("--------", this.searchVal);
        if (!this.searchVal) {
          this.$Message.info("请输入关键字");
          return;
        }
        const ret = await statsPost("/label/getKeyWord", {
          startDate: this.startDate,
          endDate: this.endDate,
          searchVal: this.searchVal,
        });
        this.listCon = [];
        const _list = _.get(ret, ["ret"], []);
        if (_list.length <= 0) {
          this.showLoadData = true;
        }
        for (const iterator of _list) {
          this.listCon.push({
            name: iterator,
          });
        }
        this.loading = false;
      } catch (error) {
        this.$Message.info("系统繁忙，请稍后重试");
        this.loading = false;
      }
    },
    okItem() {
      if (this.chooseList.length <= 0) {
        this.$Message.info("请选择关键字");
        return;
      }
      this.$emit("chooseTab", this.chooseList);
    },

    chooseDateOk() {
      this.showTime = false;
    },

    /****************************** 设置时间 */
    setEndDateValue(date) {
      this.endDate = date;
      this.pickerEndValue = new Date(date);
      // console.log("enddate-----", date, this.startDate, this.endDate);
      if (this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
    },
    setStartDateValue(date) {
      this.startDate = date;
      this.pickerValue = new Date(date);
      if (this.endDate && this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
    },
  },

  async created() {
    // 初始化开始日期
    this.startDate = moment().subtract(3, "month").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    this.pickerValue = new Date(this.startDate);
    this.pickerEndValue = new Date(this.endDate);

    // 使用传入的参数
    // if (this.defaultStartDate) {
    //   this.startDate = moment(this.defaultStartDate).format("YYYY-MM-DD");
    //   this.pickerValue = new Date(this.startDate);
    // } else {
    //   this.startDate = moment(new Date(this.pickerValue)).format("YYYY-MM-DD");
    // }

    // // 初始化结束日期
    // if (this.defaultEndDate) {
    //   this.endDate = moment(this.defaultEndDate).format("YYYY-MM-DD");
    //   this.pickerEndValue = new Date(this.endDate);
    // } else {
    //   this.endDate = moment(new Date(this.pickerEndValue)).format("YYYY-MM-DD");
    // }
  },
};
</script>
