<style scoped>
.second-label {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  background-color: #fff;
}
.label {
  padding: 10px 15px;
  color: grey;
  height: 100%;
  line-height: 100%;
}
.s-active {
  transition: all 1s;
  background-color: #efefef;
  color: black;
}
</style>
<template>
  <div v-if="labelList && labelList.length > 0">
    <Tabs :value="active" @on-click="chooseItem">
      <TabPane
        v-for="(item, index) in labelList"
        :key="index"
        :label="item.name"
        :name="item.id"
      ></TabPane>
    </Tabs>
  </div>
</template>

<script>
import _ from "lodash";

import {
  getNewUserLabelData,
  getNewContentLabelData,
  getKeyWordLabelData,
} from "../api/cacheDept";

export default {
  name: "labelCom",
  data() {
    return {
      active: "",
      labelList: [],
      curLabel: {},
      labelChildrenList: [],
    };
  },
  props: {
    // 标签类型 user | keyword | content
    labelType: {
      type: String,
      default: "user",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    refresh: function () {
      console.log("------", this.refresh);
      if ("keyword" == this.labelType) {
        this.initLabel();
      } else {
        const idArr = this.getAllChildId(this.curLabel);
        this.$emit("chooseTab", idArr);
      }
    },
  },
  computed: {},
  methods: {
    chooseItem(id) {
      const item = _.find(this.labelList, (o) => {
        return o.id == id;
      });
      this.active = item.id || "";
      const idArr = this.getAllChildId(this.curLabel);
      this.$emit("chooseTab", idArr);
    },
    getAllChildId(item) {
      let idArr = [item.id];
      if (item.children) {
        for (const iterator of item.children) {
          idArr.push(iterator.id);
          if (iterator.children) {
            for (const level3 of iterator.children) {
              idArr.push(level3.id);
            }
          }
        }
      }
      return idArr;
    },

    async initLabel() {
      this.labelList = await this.getLabelByType(this.labelType);
      const _curItem = this.labelList ? this.labelList[0] : {};
      this.active = _curItem.id || "";
      this.curLabel = _curItem;
      const idArr = this.getAllChildId(this.curLabel);
      this.$emit("chooseTab", idArr);
    },
    async getLabelByType(labelType) {
      console.log("labelType------", labelType);
      if ("user" == labelType) {
        return await getNewUserLabelData();
      }
      if ("content" == labelType) {
        return await getNewContentLabelData();
      }
      if ("keyword" == labelType) {
        return await getKeyWordLabelData(this.startDate, this.endDate);
      }
      return [];
    },
  },

  async created() {
    this.initLabel();
  },
};
</script>
