<template>
  <label-cmpt
    :showTitle="'关键字-单位阅读'"
    :showRefreshDataCmp="false"
    :taskType="'keywords'"
    :midType="'dept'"
    :labelType="'keyword'"
  ></label-cmpt>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import labelCmpt from "./labelCmptNew.vue";

// import labelCmpt from "./labelCmpt.vue";

export default {
  name: "keywordDeptStats",
  components: {
    labelCmpt,
  },

  data() {
    return {};
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
</style>
