<style scoped>
.contentKeyWord {
  text-align: center;
  background: white;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* box-shadow: 0 1px 3px rgba(105, 93, 93, 0.2); */
  padding: 3px 2px;
  box-shadow: 0px 3px 3px #1e9bcc0d;
}

::-webkit-scrollbar {
  width: 0;
}
</style>
<template>
  <div>
    <div style="">
      <div
        style="
          display: flex;
          justify-content: space-between;
          position: relative;
          margin: 6px 0;
        "
        v-show="listCon.length"
      >
        <div style="color: #ccc">
          {{ allcheck }}
          {{ `选择关键字${chooseList.length}/${listCon.length}` }}
        </div>
        <div
          :class="`deptlistItem ${allcheck ? 'active' : ''}`"
          @click="clickHandler()"
        >
          全选
          <input :checked="allcheck" type="checkbox" />
        </div>
      </div>
      <div style="overflow: scroll">
        <div
          v-for="(item, index) in listCon"
          :key="index"
          class="contentKeyWord"
        >
          <div style="flex: 1; text-align: left">{{ item.name }}</div>
          <div
            style="
              display: flex;
              flex: 1;
              padding: 8px 0;
              justify-content: flex-end;
            "
          >
            <div
              :class="`deptlistItem ${allcheck ? 'active' : ''}`"
              @click="clickHandler(item, index)"
            >
              <input
                v-model="item.checked"
                :id="`check${index}`"
                :ref="`check${index}`"
                :checked="item.checked"
                type="checkbox"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "keywordListCom",
  data() {
    return {
      allcheck: false,
      showDetailClass: "hide",
      chooseList: [],
    };
  },
  props: {
    // 内容数据
    listCon: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    chooseItem(data) {
      this.$emit("chooseItem", data);
    },
    clickHandler(item, key) {
      const curchoose = item ? item : "allcheck";
      this.chooseList = [];
      // 若点击全选
      if (curchoose == "allcheck") {
        this.allcheck = !this.allcheck;
        if (this.allcheck) {
          for (const [index, iterator] of this.listCon.entries()) {
            iterator["checked"] = true;
            this.$refs[`check${index}`][0].checked = true;
          }
          this.chooseList = _.map(this.listCon, "name"); // 全选当前可见文章的全部数据
        } else {
          for (const [index, iterator] of this.listCon.entries()) {
            iterator["checked"] = false;
            this.$refs[`check${index}`][0].checked = false;
          }
          this.chooseList = []; // 清空选项，默认全选
        }
      } else {
        let _tmpallcheckStatus = true;
        this.listCon[key].checked = this.$refs[`check${key}`][0].checked;
        for (let [index, iterator] of this.listCon.entries()) {
          // 全选/非权限
          if (!this.$refs[`check${index}`][0].checked) {
            if (_tmpallcheckStatus) {
              // 判定是否全选状态，当已经有非选中项，则不重新赋值
              _tmpallcheckStatus = false;
            }
          } else {
            this.chooseList.push(iterator.name); // 记录选中文章信息
          }
        }

        this.allcheck = _tmpallcheckStatus;
      }

      this.$emit("chooseItem", this.chooseList);
    },
  },

  watch: {
    allcheck: function (val) {
      console.log("-------", this.allcheck);
    },
  },
  beforeDestroy() {
    // 记录之前的选中状态
    console.log("allcheck-------", this.allcheck);
    // localStorage.setItem("allcheck", this.allcheck);
  },
  created() {
    // this.allcheck = localStorage.setItem("allcheck", this.allcheck);
  },
};
</script>
