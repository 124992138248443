<style scoped>
.sysSetLabelParrent {
  border-bottom: 1px solid #ececec;
  margin: 4px 0;
  border-radius: 3px;
}

.sysSetLabel {
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(96, 125, 139, 0.44),
    0px 2px 2px 0px rgba(148, 114, 104, 0.28),
    0px 1px 5px 0px rgba(30, 182, 248, 0.38);
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  margin: 0.3rem;
  text-align: center;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
}
.parrentStyle {
  font-weight: bold;
}
</style>
<template>
  <div v-if="showModal">
    <Modal v-model="sureChooseLabel" fullscreen :closable="false">
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <!-- <span>当前选择:{{ curShowList.name }}</span> -->
        <span>请选择标签</span>
      </p>
      <div v-if="labelList && labelList.length > 0">
        <Tree
          :data="labelList"
          multiple
          expand
          @on-select-change="deptChange"
        ></Tree>
      </div>

      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="okItem">确定</Button>
        <Button type="default" size="large" @click="cancel">取消</Button>
      </div>
    </Modal>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
  </div>
</template>

<script>
import _ from "lodash";
import diaLogliu from "./dialogLiu.vue";

import {
  getNewUserLabelData,
  getNewContentLabelData,
  getKeyWordLabelData,
} from "../api/cacheDept";

import commonTop from "./commonTop.vue";
export default {
  name: "labelCom",
  data() {
    return {
      sureChooseLabel: false,
      curLabel: {},
      curShowList: [],
      active: "",
      labelList: [],
      labelChildrenList: [],

      // 提示框
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },
  components: {
    commonTop,
    diaLogliu,
  },
  props: {
    // 标签类型 user | keyword | content
    labelType: {
      type: String,
      default: "user",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    refresh: function () {
      console.log("------", this.refresh);
      const idArr = this.getAllChildId(this.curShowList);
      this.$emit("chooseTab", idArr);
      this.$emit("chooseTabList", this.curShowList);
    },

    showModal: function (val) {
      this.sureChooseLabel = this.showModal;
    },
  },
  computed: {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    okItem() {
      if (this.curShowList.length <= 0) {
        this.dailogshow = true;
        this.dailogoptions.content = "请选择单位";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
      const idArr = this.getAllChildId(this.curShowList);
      this.$emit("chooseTab", idArr);
      this.$emit("chooseTabList", this.curShowList);
    },

    deptChange(deptArr, curDept) {
      this.curLabel = _.omit(curDept, ["nodeKey", "selected"]);
      this.curShowList = deptArr;
    },

    getAllChildId(deptList, type) {
      let idArr = [];
      if (type == "all") {
        for (const iterator of this.labelList) {
          idArr.push(iterator.id);
          for (const levle2 of iterator.children) {
            idArr.push(levle2.id);
            if (levle2.children) {
              for (const level3 of levle2.children) {
                idArr.push(level3.id);
              }
            }
          }
        }
      } else {
        // console.log("---------", deptList, type);
        for (const iterator of deptList) {
          idArr.push(iterator.id);
          if (iterator.children) {
            for (const level2 of iterator.children) {
              if (level2.children) {
                idArr.push(level2.id);
                for (const level3 of level2.children) {
                  idArr.push(level3.id);
                }
              }
            }
          }
        }
      }
      // console.log("idArr-------", _.uniq(idArr));

      return _.uniq(idArr);
    },

    async initLabel(type) {
      this.labelList = await this.getLabelByType(this.labelType);

      // 取全部字部门Id
      const idArr = this.getAllChildId(this.curShowList, type);
      // this.$emit("chooseTab", idArr); // 默认全部
      this.$emit("chooseTab", []); // 默认空
      this.$emit("chooseTabList", []);
    },
    async getLabelByType(labelType) {
      if ("user" == labelType) {
        return await getNewUserLabelData();
      }
      if ("content" == labelType) {
        return await getNewContentLabelData();
      }
      if ("keyword" == labelType) {
        return await getKeyWordLabelData(this.startDate, this.endDate);
      }
      return [];
    },
  },

  async created() {
    this.initLabel("all");
  },
};
</script>
