import { render, staticRenderFns } from "./labelNewOrder1Com.vue?vue&type=template&id=0a9ac3a5&scoped=true"
import script from "./labelNewOrder1Com.vue?vue&type=script&lang=js"
export * from "./labelNewOrder1Com.vue?vue&type=script&lang=js"
import style0 from "./labelNewOrder1Com.vue?vue&type=style&index=0&id=0a9ac3a5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a9ac3a5",
  null
  
)

export default component.exports