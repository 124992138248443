// 结果类型
export const MID_TYPE = {
  user: "user",
  dept: "dept",
  userLabel: "userLabel",
};
export const TASK_TYPE = {
  UserLabel: "人员标签统计", //    uLabel
  ContentLabel: "文章标签统计", // cLabel
  Keyword: "文章关键字", // keywords
  LabelRead: "标签阅读统计",
};

export const TASK_TYPE_NEW = {
  wztj: "文章统计",
  deptTj: "单位统计",
  report: "个人单位报告",
  uLabel: "职工标签", // 职工标签，统计内容：
  cLabel: "文章标签", // 文章标签，统计内容：
  keywords: "文章keywords",
};
